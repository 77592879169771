import ThreeDotsIcon from '../../../../../../../../styles/img/icons/three_dots_white.svg';
interface ShortcutProps {
  logo: string;
  name: string;
}
const Shortcut = (props: ShortcutProps) => {
  return (
    <div className="shortcut">
      <img className="shortcut__three-dots-button" src={ThreeDotsIcon} />
      <div className="shortcut__logo-container">
        <img className="shortcut__logo" src={props.logo} />
      </div>
      <p className="shortcut__text">{props.name}</p>
    </div>
  );
};

export default Shortcut;
