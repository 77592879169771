import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

interface ArticleSearchResultProps {
  search: string;
  score: number;
  publisher: string;
  headline: string;
  abstract: string;
  url: string;
  image?: string | undefined;
  favicon?: string | undefined;
  upVotes: number;
  downVotes: number;
  userVote: string;
  vote: Function;
  userIdentifier: string;
}

const ArticleSearchResult = (props: ArticleSearchResultProps) => {
  const [upVotes, setUpVotes] = useState(props.upVotes);
  const [upVoted, setUpVoted] = useState(props.userVote === 'Useful');
  const [downVotes, setDownVotes] = useState(props.downVotes);
  const [downVoted, setDownVoted] = useState(props.userVote === 'Not Useful');

  useEffect(() => {
    setUpVotes(props.upVotes);
    setUpVoted(props.userVote === 'Useful');
    setDownVotes(props.downVotes);
    setDownVoted(props.userVote === 'Not Useful');
  }, [props.upVotes, props.downVotes, props.userVote]);
  const voteUp = async () => {
    if (upVoted) {
      setUpVotes(upVotes - 1);
      setUpVoted(false);
      await props.vote(props.url, props.search, props.userIdentifier, true, false);
      return;
    }

    if (downVoted) {
      setDownVotes(downVotes - 1);
      setDownVoted(false);
      await props.vote(props.url, props.search, props.userIdentifier, false, false);
    }
    setUpVotes(upVotes + 1);
    setUpVoted(true);
    await props.vote(props.url, props.search, props.userIdentifier, true, true);
  };

  const voteDown = async () => {
    if (downVoted) {
      setDownVotes(downVotes - 1);
      setDownVoted(false);
      await props.vote(props.url, props.search, props.userIdentifier, false, false);
      return;
    }

    if (upVoted) {
      setUpVotes(upVotes - 1);
      setUpVoted(false);
      await props.vote(props.url, props.search, props.userIdentifier, true, false);
    }
    setDownVotes(downVotes + 1);
    setDownVoted(true);
    await props.vote(props.url, props.search, props.userIdentifier, false, true);
  };

  return (
    <>
      <ReactTooltip place="left" />
      <div className="search-result">
        <div className="search-result__text">
          <div onClick={() => (window.location.href = props.url)} className="search-result__author">
            {' '}
            {props.favicon && props.favicon.includes('https') && (
              <img
                className="search-result__favicon"
                src={props.favicon}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.hidden = true;
                }}
              ></img>
            )}
            {props.publisher}
          </div>
          <a href={props.url} className="search-result__title">
            {props.headline}
          </a>
          <div onClick={() => (window.location.href = props.url)} className="search-result__claim search-result__article-search-result">
            <span className="search-result__search-resulted ">{props.abstract}</span>
          </div>
          <div className="search-result__votes">
            <div className="search-result__up-vote">
              <svg
                data-tip="Útil"
                onClick={e => {
                  e.stopPropagation();
                  voteUp();
                }}
                className={`search-result__vote-button ${upVoted && 'search-result__vote-button-pressed'}`}
                viewBox="0 0 16 16"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <g>
                  <path d="M12.42,14A1.54,1.54,0,0,0,14,12.87l1-4.24C15.12,7.76,15,7,14,7H10l1.48-3.54A1.17,1.17,0,0,0,10.24,2a1.49,1.49,0,0,0-1.08.46L5,7H1v7ZM9.89,3.14A.48.48,0,0,1,10.24,3a.29.29,0,0,1,.23.09S9,6.61,9,6.61L8.46,8H14c0,.08-1,4.65-1,4.65a.58.58,0,0,1-.58.35H6V7.39ZM2,8H5v5H2Z"></path>
                </g>
              </svg>
              <span className="search-result__vote-number">{upVotes}</span>
            </div>
            <div className="search-result__down-vote">
              <svg
                data-tip="No útil"
                onClick={e => {
                  e.stopPropagation();
                  voteDown();
                }}
                className={`search-result__vote-button ${downVoted && 'search-result__vote-button-pressed'}`}
                viewBox="0 0 16 16"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <g>
                  <path d="M3.54,2A1.55,1.55,0,0,0,2,3.13L1,7.37C.83,8.24,1,9,2,9H6L4.52,12.54A1.17,1.17,0,0,0,5.71,14a1.49,1.49,0,0,0,1.09-.46L11,9h4V2ZM6.07,12.86a.51.51,0,0,1-.36.14.28.28,0,0,1-.22-.09l0-.05L6.92,9.39,7.5,8H2a1.5,1.5,0,0,1,0-.41L3,3.35A.58.58,0,0,1,3.54,3H10V8.61ZM14,8H11l0-5h3Z"></path>
                </g>
              </svg>
              <span className="search-result__vote-number">{downVotes}</span>
            </div>
          </div>
        </div>
        <div className="search-result__img-container">
          <img
            onClick={() => (window.location.href = props.url)}
            className="search-result__img"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = 'https://facta-pages.s3.eu-west-1.amazonaws.com/double-check.png';
            }}
            src={props.image ? props.image : 'https://facta-pages.s3.eu-west-1.amazonaws.com/double-check.png'}
          />
        </div>
        <div className="search-result__score">Score: {props.score.toFixed(2)}</div>
      </div>
    </>
  );
};
export default ArticleSearchResult;
