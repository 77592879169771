import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClaimReviewSearchService } from '../../services/claim-review-search.service';
import Searcher from './components/Searcher/Searcher';
import UserMenu from './components/UserMenu/UserMenu';

const HomePage = () => {
  const claimReviewSearchService = new ClaimReviewSearchService();
  const [searchText, setSearchText] = useState('');
  const [factChecksCount, setFactChecksCount] = useState(0);
  const [writingSearchText, setWritingSearchText] = useState(false);
  const navigate = useNavigate();

  const search = (text: string) => {
    navigate(`/search?q=${text}`);
  };
  useEffect(() => {
    const getFactChecksCount = async () => {
      setFactChecksCount(Math.round((await claimReviewSearchService.searchClaimReviews('* ', false)).total / 1.95));
    };
    getFactChecksCount();
  }, []);
  return (
    <div className="search-engine">
      <div className={`search-engine__background ${writingSearchText ? 'search-engine__background--unfocused' : ''}`}></div>
      <UserMenu profilePicture="https://lh3.googleusercontent.com/-OQcLplnzTl0/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucm2Ot8g5QVnjENk709oPDrIr6chxQ/s48-cc-md/photo.jpg" />
      <Searcher searchProps={{ searchText, setSearchText, writingSearchText, setWritingSearchText, search, factChecksCount }} />
    </div>
  );
};

export default HomePage;
