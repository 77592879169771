import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../../styles/img/icons/logo_color.png';
import NavBar from './components/NavBar/NavBar';
import SearchHeaderSearchBar from './components/SearchHeaderSearchBar/SearchHeaderSearchBar';
import SearchHeaderUserMenu from './components/SearchHeaderUserMenu/SearchHeaderUserMenu';
import SideBar from './components/SideBar/SideBar';

interface SearchHeaderProps {
  searchProps: {
    searchText: string;
    setSearchText: Function;
    search: Function;
    multilanguageSearch: boolean;
    setMultilanguageSearch: Function;
  };
}
const SearchHeader = ({ searchProps }: SearchHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="search-header">
      <SideBar />
      <SearchHeaderUserMenu profilePicture="https://lh3.googleusercontent.com/-OQcLplnzTl0/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucm2Ot8g5QVnjENk709oPDrIr6chxQ/s48-cc-md/photo.jpg" />
      <div className="search-header__main">
        <div className="search-header__search-bar-container">
          <div
            className="search-header__logo-container"
            onClick={() => {
              navigate('/');
            }}
          >
            <img className="search-header__logo" src={Logo} />
            <div className="search-header__logo-text">2Checks</div>
          </div>
          <SearchHeaderSearchBar searchProps={searchProps} />
        </div>
      </div>
      <div className="search-header__multilanguage-switch">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={searchProps.multilanguageSearch}
                onChange={() => {
                  searchProps.setMultilanguageSearch(!searchProps.multilanguageSearch);
                }}
              />
            }
            label="Búsqueda multidioma"
          />
        </FormGroup>
      </div>
      <NavBar />
    </div>
  );
};

export default SearchHeader;
