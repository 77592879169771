import ImageIcon from '../../../../../../styles/img/icons/image_black.svg';
import NewsIcon from '../../../../../../styles/img/icons/newspaper_black.svg';
import PlaceIcon from '../../../../../../styles/img/icons/place_black.svg';
import SearchIcon from '../../../../../../styles/img/icons/search_black.svg';
import VideoIcon from '../../../../../../styles/img/icons/smart_display_black.svg';
import ThreeDotsIcon from '../../../../../../styles/img/icons/three_dots_black.svg';
const NavBar = () => {
  return (
    <div className="nav-container">
      <div className="nav-bar__shadow"></div>
      <div className="nav-bar">
        <div className="nav-bar__option">
          <img className="nav-bar__option-icon" src={SearchIcon}></img>
          <div className="nav-bar__option-text">Todo</div>
        </div>
        <div className="nav-bar__option">
          <img className="nav-bar__option-icon" src={NewsIcon}></img>
          <div className="nav-bar__option-text">Noticias</div>
        </div>
        <div className="nav-bar__option">
          <img className="nav-bar__option-icon" src={VideoIcon}></img>
          <div className="nav-bar__option-text">Videos</div>
        </div>
        <div className="nav-bar__option">
          <img className="nav-bar__option-icon" src={ImageIcon}></img>
          <div className="nav-bar__option-text">Imágenes</div>
        </div>
        <div className="nav-bar__option">
          <img className="nav-bar__option-icon" src={PlaceIcon}></img>
          <div className="nav-bar__option-text">Maps</div>
        </div>
        <div className="nav-bar__option">
          <img className="nav-bar__option-icon" src={ThreeDotsIcon}></img>
          <div className="nav-bar__option-text">Más</div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
