import Shortcut from './components/Shortcut/Shortcut';
const ShortcutsContainer = () => {
  return (
    <div className="shortcuts-container">
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://bitbucket.org/dashboard/overview&size=24"
        name="Overview - Bitbucket"
      />
      <Shortcut
        logo="https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://web.whatsapp.com/&size=24"
        name="Whatsapp"
      />
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.datawrapper.de/&size=24"
        name="Datawrapper: Create charts, maps, and tables"
      />
      <Shortcut
        logo="https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.newtral.es/&size=24"
        name="Newtral - Periodismo, fact-checking, tecnología y datos"
      />
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://aws.amazon.com/es/&size=24"
        name="AWS | Cloud Computing - Servicios de informática en la nube"
      />
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://bitbucket.org/dashboard/overview&size=24"
        name="Overview - Bitbucket"
      />
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://bitbucket.org/dashboard/overview&size=24"
        name="Overview - Bitbucket"
      />
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://bitbucket.org/dashboard/overview&size=24"
        name="Overview - Bitbucket"
      />
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://bitbucket.org/dashboard/overview&size=24"
        name="Overview - Bitbucket"
      />
      <Shortcut
        logo="https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://bitbucket.org/dashboard/overview&size=24"
        name="Overview - Bitbucket"
      />
    </div>
  );
};

export default ShortcutsContainer;
