import AppsIcon from '../../../../styles/img/icons/apps_white.svg';
interface UserMenuProps {
  profilePicture: string;
}
const UserMenu = (props: UserMenuProps) => {
  return (
    <div className="user-menu">
      <div className="user-menu__option">About</div>
      <div className="user-menu__option">Gmail</div>
      <div className="user-menu__option">
        <img className="user-menu__apps-icon" src={AppsIcon} />
      </div>

      <div className="user-menu__option">
        <img className="user-menu__profile-picture" src={props.profilePicture} />
      </div>
    </div>
  );
};

export default UserMenu;
