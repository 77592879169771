import AppsIconBlack from '../../../../../../styles/img/icons/apps_black.svg';
interface SearchHeaderUserMenuProps {
  profilePicture: string;
}
const SearchHeaderUserMenu = (props: SearchHeaderUserMenuProps) => {
  return (
    <div className="search-header-user-menu">
      <div className="search-header-user-menu__option">About</div>
      <div className="search-header-user-menu__option">Gmail</div>
      <div className="search-header-user-menu__option">
        <img className="search-header-user-menu__apps-icon" src={AppsIconBlack} />
      </div>
      <div className="search-header-user-menu__option">
        <img className="search-header-user-menu__profile-picture" src={props.profilePicture} />
      </div>
    </div>
  );
};

export default SearchHeaderUserMenu;
