import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import * as FaIcons from 'react-icons/fa';
import { FaHome } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';
import { IoIosApps } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Logo from '../../../../../../styles/img/icons/logo_color.png';

const SideBar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#999999' }}>
        <div className="side-container">
          <div className="side-bar">
            <Link to="#" className="menu-bars-burger">
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <div className="backDrop" onClick={showSidebar}></div>
            <div className="nav_menu__container">
              <div className="logo__container">
                <img className="side__logo" src={Logo} />
                <div className="side__logo-text">2Checks</div>
              </div>
              <ul className="nav-menu-items">
                <li className="navbar-toggle">
                  <Link to="/" className="menu-bars">
                    <FaHome />
                    <span>Página principal</span>
                  </Link>
                  <Link to="#" className="menu-bars">
                    <IoIosApps />
                    <span>Colecciones</span>
                  </Link>
                  <Link to="#" className="menu-bars">
                    <FiHelpCircle />
                    <span>Ayuda</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default SideBar;
