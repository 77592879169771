export class ClaimReviewSearchService {
  private readonly claimReviewSearchUrl: string;
  constructor() {
    this.claimReviewSearchUrl = process.env.REACT_APP_CLAIM_REVIEW_SEARCH_URL ?? 'https://search.claimreview.dev.newtral.es';
  }

  async getUserIdentifier() {
    const response = await fetch(`${this.claimReviewSearchUrl}/users/id`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });

    return await response.text();
  }
  async getUserVote(urls: string[], searches: string[], userIdentifier: string) {
    const response = await fetch(`${this.claimReviewSearchUrl}/votes`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify({
        urls,
        searches,
        userIdentifier
      })
    });

    return await response.json();
  }

  async vote(url: string, search: string, userIdentifier: string, isUseful: boolean, isAdd: boolean) {
    const response = await fetch(`${this.claimReviewSearchUrl}/votes`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify({
        url,
        search,
        userIdentifier,
        isUseful,
        isAdd
      })
    });

    return await response.json();
  }

  async searchClaimReviews(claimReviewedDSLQuery: string, multilingual = false) {
    const response = await fetch(
      `${this.claimReviewSearchUrl}/claimreviews?${new URLSearchParams({
        claimReviewedDSLQuery,
        multilingual: multilingual.toString()
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }
    );

    return await response.json();
  }

  async searchArticles(articleDSLQuery: string, multilingual = false) {
    const response = await fetch(
      `${this.claimReviewSearchUrl}/articles?${new URLSearchParams({
        articleDSLQuery,
        multilingual: multilingual.toString()
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }
    );

    return await response.json();
  }

  async searchResearches(researchDSLQuery: string, multilingual = false) {
    const response = await fetch(
      `${this.claimReviewSearchUrl}/researches?${new URLSearchParams({
        researchDSLQuery,
        multilingual: multilingual.toString()
      })}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }
    );

    return await response.json();
  }

  async getAppearances(url: string) {
    const response = await fetch(`${this.claimReviewSearchUrl}/appearances?${new URLSearchParams({ url })}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    });

    return await response.json();
  }
}
