import { Article } from '../../../../dtos/article.dto';
import { ClaimReview } from '../../../../dtos/claim-review.dto';
import { Research } from '../../../../dtos/research.dto';
import ArticleSearchResult from './components/ArticleSearchResult/ArticleSearchResult';
import ClaimReviewSearchResult from './components/ClaimReviewSearchResult/ClaimReviewSearchResult';
import ResearchSearchResult from './components/ResearchSearchResult/ResearchSearchResult';
interface SearchResultsProps {
  search: string;
  claimReviews: ClaimReview[];
  articles: Article[];
  researches: Research[];
  vote: Function;
  userIdentifier: string;
}

const SearchResults = (props: SearchResultsProps) => {
  const claimReviewsList = props.claimReviews.map((claimReview, index) => {
    const claimReviewData = {
      score: claimReview._score,
      author: claimReview._source.author.name,
      title: claimReview._source.title,
      claimReviewed: claimReview._source.claimReviewed,
      reviewRating: claimReview._source.reviewRating?.alternateName,
      reviewRatingExplanation: claimReview._source.reviewRating?.ratingExplanation,
      url: claimReview._source.url,
      image: claimReview._source.image,
      favicon: claimReview._source.favicon,
      upVotes: claimReview._source.upVotes,
      downVotes: claimReview._source.downVotes,
      userVote: claimReview._source.userVote
    };
    return (
      <div key={`${index.toString()}-${claimReviewData.url}`}>
        <ClaimReviewSearchResult {...claimReviewData} vote={props.vote} search={props.search} userIdentifier={props.userIdentifier} />
      </div>
    );
  });

  const articlesList = props.articles.map((article, index) => {
    const claimReviewData = {
      score: article._score,
      publisher: article._source.publisher.name,
      headline: article._source.headline,
      abstract: article._source.abstract,
      url: article._source.url,
      image: article._source.image,
      favicon: article._source.favicon,
      upVotes: article._source.upVotes,
      downVotes: article._source.downVotes,
      userVote: article._source.userVote
    };

    return (
      <div key={`${index.toString()}-${claimReviewData.url}`}>
        <ArticleSearchResult {...claimReviewData} vote={props.vote} search={props.search} userIdentifier={props.userIdentifier} />
      </div>
    );
  });
  const researchesList = props.researches.map((research, index) => {
    const researchData = {
      score: research._score,
      title: research._source.title,
      summary: research._source.summary
    };

    return (
      <div key={`research-result-${index}`}>
        <ResearchSearchResult {...researchData} search={props.search} userIdentifier={props.userIdentifier} />
      </div>
    );
  });
  return (
    <div className="search-results">
      <div className="search-results-claimreviews-list">{claimReviewsList}</div>
      <div className="search-results-articles-list">
        <p className="search-results-articles-list-title">Artículos relacionados:</p>
        {articlesList}
      </div>
      <div className="search-results-articles-list">
        <p className="search-results-articles-list-title">Consultas relacionadas:</p>
        {researchesList}
      </div>
    </div>
  );
};

export default SearchResults;
