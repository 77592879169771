import { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import ExpandLessIcon from '../../../../../../styles/img/icons/expand_less.svg';
import ExpandMoreIcon from '../../../../../../styles/img/icons/expand_more.svg';
interface ResearchSearchResultProps {
  search: string;
  score: number;
  title: string;
  summary: string;
  userIdentifier: string;
}

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const ResearchSearchResult = (props: ResearchSearchResultProps) => {
  const [overflowActive, setOverflowActive] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  const overflowingText = useRef<HTMLDivElement | null>(null);

  const checkOverflow = (textContainer: HTMLDivElement | null): boolean => {
    if (textContainer) {
      return textContainer.offsetHeight < textContainer.scrollHeight;
    }

    return false;
  };
  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [overflowActive]);

  const linkify = (text: string) =>
    text.split(' ').map(part =>
      URL_REGEX.test(part) ? (
        <a key={part} href={part} target="_blank" rel="noreferrer">
          {part}{' '}
        </a>
      ) : (
        part + ' '
      )
    );
  return (
    <>
      <ReactTooltip place="left" />
      <div className={`search-result ${showMore ? 'search-result-expanded' : ''}`}>
        <div className="search-result__score">Score: {props.score.toFixed(2)}</div>

        <div className="search-result__text search-result__research-text">
          <a className="search-result__title search-result__research-title">{props.title}</a>
          <div className="search-result__claim search-result__research-search-result" ref={overflowingText}>
            <div className="search-result__search-resulted ">{linkify(props.summary)}</div>
          </div>
          {overflowActive && (
            <button className="search-result__view-more" onClick={() => setShowMore(!showMore)}>
              <img className="search-result__view-more-icon" src={showMore ? ExpandLessIcon : ExpandMoreIcon}></img>
            </button>
          )}
        </div>
        <div className="search-result__img-container">
          <img
            className="search-result__img search-result__research-img"
            src="https://facta-pages.s3.eu-west-1.amazonaws.com/double-check.png"
          />
        </div>
      </div>
    </>
  );
};
export default ResearchSearchResult;
