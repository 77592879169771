import CloseIcon from '../../../../../../styles/img/icons/close.svg';
import SearchIcon from '../../../../../../styles/img/icons/search.svg';

interface SearchBarProps {
  searchProps: {
    searchText: string;
    setSearchText: Function;
    writingSearchText: boolean;
    setWritingSearchText: Function;
    search: Function;
  };
}
const SearchBar = ({ searchProps }: SearchBarProps) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      searchProps.search(searchProps.searchText);
    }
  };
  return (
    <div className={`search-bar ${searchProps.writingSearchText ? 'search-bar--expanded' : ''}`}>
      <input
        value={searchProps.searchText}
        onChange={e => {
          searchProps.setSearchText(e.target.value);
          searchProps.setWritingSearchText(e.target.value.length > 0);
        }}
        className="search-bar__text-input"
        placeholder="Busca en 2Checks y lucha contra la desinformación"
        onKeyDown={handleKeyDown}
      ></input>{' '}
      <div className="search-bar__icons">
        <img
          className="search-bar__delete-search"
          src={CloseIcon}
          onClick={() => {
            searchProps.setSearchText('');
            searchProps.setWritingSearchText(false);
          }}
        ></img>
        <span className="vertical-separator"></span>
        <img className="search-bar__search" src={SearchIcon} onClick={() => searchProps.search(searchProps.searchText)}></img>
      </div>
    </div>
  );
};

export default SearchBar;
