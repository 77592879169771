import ReactTooltip from 'react-tooltip';
import FactCheckIcon from '../../../../styles/img/icons/fact_check_white.svg';
import Logo from '../../../../styles/img/icons/logo.png';
import SearchBar from './components/SearchBar/SearchBar';
import ShortcutsContainer from './components/ShortcutsContainer/ShortcutsContainer';
interface SearcherProps {
  searchProps: {
    searchText: string;
    setSearchText: Function;
    writingSearchText: boolean;
    setWritingSearchText: Function;
    search: Function;
    factChecksCount: number;
  };
}
const Searcher = ({ searchProps }: SearcherProps) => {
  return (
    <>
      <ReactTooltip place="bottom" />
      <div className="searcher">
        <div className="searcher__header">
          <div className="searcher__title">
            <img className="searcher__logo" src={Logo}></img>
            <h2 className="searcher__name">2Checks</h2>
          </div>
          <div
            data-tip={`${searchProps.factChecksCount.toLocaleString('es-AR')} Fact Checks en nuestra base de datos`}
            className="searcher__fact-checks-counter-container"
          >
            <div className="searcher__fact-checks-count">{searchProps.factChecksCount.toLocaleString('es-AR')}</div>
            <img className="searcher__fact-checks-icon" src={FactCheckIcon}></img>
          </div>
        </div>

        <SearchBar searchProps={searchProps} />
        <ShortcutsContainer />
      </div>
    </>
  );
};

export default Searcher;
