import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonSearchResults = () => {
  return (
    <div className="search-results-container">
      <p className="search-info">
        {' '}
        <Skeleton height={15} width={'25%'} />
      </p>
      <div className="search-results">
        {Array.from(Array(5).keys()).map((element, index) => (
          <div className="search-result" key={`skeleton-search-result__${index}`}>
            <div className="search-result__text search-result__research-text">
              <div className="search-result__title">
                <Skeleton height={30} />
                <br />
              </div>
              <div className="search-result__claim">
                <div className="search-result__search-resulted ">
                  <Skeleton height={10} />
                  <Skeleton height={10} />
                </div>
              </div>
            </div>
            <div className="search-result__img-container">
              <Skeleton width={112} height={112} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SkeletonSearchResults;
