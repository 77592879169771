import CloseIcon from '../../../../../../styles/img/icons/close.svg';
import SearchIcon from '../../../../../../styles/img/icons/search.svg';

interface SearchHeaderSearchBarProps {
  searchProps: {
    searchText: string;
    setSearchText: Function;
    search: Function;
  };
}
const SearchHeaderSearchBar = ({ searchProps }: SearchHeaderSearchBarProps) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      searchProps.search();
    }
  };
  return (
    <div className="search-header-search-bar">
      <input
        value={searchProps.searchText}
        onChange={e => {
          searchProps.setSearchText(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        className="search-header-search-bar__text-input"
        placeholder="Busca para luchar contra la desinformación"
      ></input>{' '}
      <div className="search-header-search-bar__icons">
        <img
          className="search-header-search-bar__delete-search"
          src={CloseIcon}
          onClick={() => {
            searchProps.setSearchText('');
          }}
        ></img>
        <span className="vertical-separator"></span>
        <img
          className="search-header-search-bar__search"
          src={SearchIcon}
          onClick={() => {
            searchProps.search();
          }}
        ></img>
      </div>
    </div>
  );
};

export default SearchHeaderSearchBar;
