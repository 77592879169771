import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Article } from '../../dtos/article.dto';
import { ClaimAppearance } from '../../dtos/claim-appearance.dto';
import { ClaimReview } from '../../dtos/claim-review.dto';
import { ClaimReviewSearchService } from '../../services/claim-review-search.service';
import Popup from './components/Popup/Popup';
import SearchHeader from './components/SearchHeader/SearchHeader';
import SearchResults from './components/SearchResults/SearchResults';
import SkeletonSearchResults from './components/SkeletonSearchResults/SkeletonSearchResults';

import 'react-loading-skeleton/dist/skeleton.css';

const claimReviewSearchService = new ClaimReviewSearchService();

const Search = () => {
  const [searchParams] = useSearchParams();

  const [searchText, setSearchText] = useState(searchParams.get('q') ?? '');
  const [searchTime, setSearchTime] = useState('');
  const [claimReviewsData, setClaimReviewsData] = useState([]);
  const [articlesData, setArticlesData] = useState([]);
  const [researchesData, setResearchesData] = useState([]);
  const [multilanguageSearch, setMultilanguageSearch] = useState(true);
  const [isUrlCheck, setIsUrlCheck] = useState(false);
  const userIdentifierRef = useRef(localStorage.getItem('userId2Checks') ?? '');
  const navigate = useNavigate();
  const getUserIdentifier = async () => {
    const localUserId = localStorage.getItem('userId2Checks');
    if (localUserId) {
      userIdentifierRef.current = localUserId;
      return;
    }
    const userId = await claimReviewSearchService.getUserIdentifier();
    localStorage.setItem('userId2Checks', userId);
    userIdentifierRef.current = userId;
  };

  const vote = async (url: string, search: string, userIdentifier: string, isUseful: boolean, isAdd: boolean) => {
    const response = await claimReviewSearchService.vote(url, search, userIdentifier, isUseful, isAdd);
    if (response.message && response.message[0] === 'userIdentifier must be longer than or equal to 1 characters') {
      await getUserIdentifier();
      await claimReviewSearchService.vote(url, search, userIdentifierRef.current, isUseful, isAdd);
    }
  };

  const getUserVotes = async (urls: string[], search: string) => {
    let response = await claimReviewSearchService.getUserVote(urls, Array(urls.length).fill(search), userIdentifierRef.current);
    if (response.message && response.message[0] === 'userIdentifier must be longer than or equal to 1 characters') {
      await getUserIdentifier();
      response = await claimReviewSearchService.getUserVote(urls, Array(urls.length).fill(search), userIdentifierRef.current);
    }
    return response;
  };

  const isUrl = (text: string) => {
    let url;
    try {
      url = new URL(text);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  const searchClaimReviews = async (txt: string) => {
    setSearchTime('');

    const t1 = new Date().getTime();
    const claimReviews = await claimReviewSearchService.searchClaimReviews(txt, multilanguageSearch);
    const claimReviewsData = multilanguageSearch ? claimReviews : claimReviews.response;

    const claimreviewsUrls = claimReviewsData.map((claimReview: ClaimReview) => claimReview._source.url.replaceAll(' ', ''));
    const claimreviewsVotes = await getUserVotes(claimreviewsUrls, txt);

    const claimReviewsWithVotes = claimReviewsData.map((claimReview: ClaimReview, index: number) => {
      claimReview._source.upVotes = claimreviewsVotes[index].upVotes;
      claimReview._source.downVotes = claimreviewsVotes[index].downVotes;
      claimReview._source.userVote = claimreviewsVotes[index].userVote;
      return claimReview;
    });

    setClaimReviewsData(claimReviewsWithVotes);
    const t2 = new Date().getTime();
    setSearchTime(((t2 - t1) / 1000).toLocaleString('es', { maximumFractionDigits: 2 }));
  };
  const searchArticles = async (txt: string) => {
    const articlesResponse = await claimReviewSearchService.searchArticles(txt, multilanguageSearch);

    const articles = multilanguageSearch ? articlesResponse : articlesResponse.response;
    const articlesUrls = articles.map((article: Article) => article._source.url);
    const articlesVotes = await getUserVotes(articlesUrls, txt);

    const articlesWithVotes = articles.map((article: Article, index: number) => {
      article._source.upVotes = articlesVotes[index].upVotes;
      article._source.downVotes = articlesVotes[index].downVotes;
      article._source.userVote = articlesVotes[index].userVote;
      return article;
    });
    setArticlesData(articlesWithVotes);
  };

  const searchResearches = async (txt: string) => {
    const researchesResponse = await claimReviewSearchService.searchResearches(txt, multilanguageSearch);
    const researches = multilanguageSearch ? researchesResponse : researchesResponse.response;
    setResearchesData(researches.filter(research => research._source.title && research._source.summary));
  };
  const search = async () => {
    try {
      setIsUrlCheck(false);
      if (!searchText) return;
      if (isUrl(searchText)) {
        setIsUrlCheck(true);
        await processPage(searchText);
        return;
      }
      navigate(`/search?q=${searchText}`);

      searchClaimReviews(searchText);
      searchArticles(searchText);
      searchResearches(searchText);
    } catch (err) {
      setClaimReviewsData([]);
    }
  };

  const removePopup = () => {
    document.getElementById('newtral-score-popup')?.remove();
  };

  const showPopup = async (claimAppearances: ClaimAppearance[]) => {
    const body = document.body as HTMLElement;
    const app = document.createElement('div');
    app.id = 'newtral-score-popup';
    body.appendChild(app);

    ReactDOM.render(
      <React.StrictMode>
        <Popup claimAppearances={claimAppearances} removePopup={removePopup} />
      </React.StrictMode>,
      app
    );
  };

  const processPage = async (url: string) => {
    const claimAppearances = await claimReviewSearchService.getAppearances(url);

    if (claimAppearances) {
      showPopup(claimAppearances);
    }
  };

  useEffect(() => {
    getUserIdentifier();
  }, []);

  useEffect(() => {
    search();
  }, []);

  return (
    <div className="search">
      <SearchHeader searchProps={{ setSearchText, searchText, search, multilanguageSearch, setMultilanguageSearch }} />
      {claimReviewsData && claimReviewsData.length && searchTime ? (
        <div className="search-results-container">
          <p className="search-info">Fact checks relacionados ({searchTime} segundos)</p>

          <SearchResults
            claimReviews={claimReviewsData}
            articles={articlesData}
            researches={researchesData}
            search={searchText}
            vote={vote}
            userIdentifier={userIdentifierRef.current}
          />
        </div>
      ) : (
        !isUrlCheck && <SkeletonSearchResults />
      )}
    </div>
  );
};

export default Search;
