import { ClaimAppearance } from '../../../../dtos/claim-appearance.dto';
import CloseIcon from '../../../../styles/img/icons/close_icon.svg';

interface PopupProps {
  claimAppearances: ClaimAppearance[];
  removePopup: Function;
}

const displayClaimAppearances = (claimAppearances: ClaimAppearance[]) => {
  return claimAppearances.length ? (
    <>
      {claimAppearances.map((claimAppearance, index) => {
        return (
          <div
            key={index.toString()}
            id={index.toString()}
            onClick={() => (window.location.href = claimAppearance.claimReviewUrl)}
            className="popup__claim-review"
          >
            <div className="popup__claim-review-author">
              {claimAppearance.claimReviewFavicon && claimAppearance.claimReviewFavicon.includes('https') && (
                <img
                  className="popup__claim-review-author-favicon"
                  src={claimAppearance.claimReviewFavicon}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.hidden = true;
                  }}
                ></img>
              )}
              {claimAppearance.claimReviewAuthor}
            </div>

            <div className="popup__claim-review-rating">{claimAppearance.rating}</div>
          </div>
        );
      })}
      <hr className="popup__separator"></hr>
    </>
  ) : (
    <></>
  );
};

const Popup = (props: PopupProps) => {
  return (
    <div className="popup">
      <div onClick={() => props.removePopup()} className="popup__close-button">
        <img src={CloseIcon} />
      </div>
      <div className="popup__content">
        {props.claimAppearances.length
          ? displayClaimAppearances(props.claimAppearances)
          : 'No se ha encontrado verificación para la URL indicada'}
      </div>
    </div>
  );
};

export default Popup;
